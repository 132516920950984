import facebook from "../assets/icons/facebook.png";

export default function Footer({
  handleNavClick,
  mainRef,
  lessonsRef,
  bioRef,
  galleryRef,
  videoRef,
  contactRef,
  mapRef,
}) {
  return (
    <>
      <footer className="footer">
        <nav className="footer__nav">
          <p
            onClick={() => handleNavClick(mainRef)}
            className="footer__nav--item"
          >
            ראשי
          </p>
          <p
            onClick={() => handleNavClick(lessonsRef)}
            className="footer__nav--item"
          >
            על השיעורים
          </p>
          <p
            onClick={() => handleNavClick(bioRef)}
            className="footer__nav--item"
          >
            קצת עלי
          </p>
          <p
            onClick={() => handleNavClick(galleryRef)}
            className="footer__nav--item"
          >
            תמונות
          </p>
          <p
            onClick={() => handleNavClick(videoRef)}
            className="footer__nav--item"
          >
            וידאו
          </p>
          <p
            onClick={() => handleNavClick(contactRef)}
            className="footer__nav--item"
          >
            יצירת קשר
          </p>
          <p
            onClick={() => handleNavClick(mapRef)}
            className="footer__nav--item"
          >
            דרכי הגעה
          </p>
          <p className="footer__nav--item">
            <a
              className="footer__nav--item-link"
              href="https://www.facebook.com/BraunGuitarSchool"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={facebook}
                alt="לינק לפייסבוק"
                className="footer__nav--item-facebook"
              />
            </a>
          </p>
        </nav>
      </footer>
    </>
  );
}
