export default function Map({ mapRef }) {
  return (
    <>
      <div className="map">
        <h2 ref={mapRef} className="map__headline">
          דרכי הגעה
        </h2>
        <div className="map__container">
          <iframe
            className="map__frame"
            title="myFrame"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3387.2469314883565!2d34.81121627627865!3d31.899887528292584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1502b70194813bdf%3A0xd30ed43fdd0a14db!2sHaim%20Weizman%20St%2027%2C%20Rehovot!5e0!3m2!1sen!2sil!4v1671005840252!5m2!1sen!2sil"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
}
