import logo from "../assets/logo.png";
import menu from "../assets/icon-menu.svg";
import menuClose from "../assets/icon-menu-close.svg";
import whatsapp from "../assets/icons/whatsapp.png";
import facebook from "../assets/icons/facebook.png";

export default function Topnav({
  handleNavClick,
  clicked,
  setClicked,
  mainRef,
  lessonsRef,
  bioRef,
  galleryRef,
  videoRef,
  contactRef,
  mapRef,
}) {
  return (
    <>
      <div className="topnav__sticky">
        <div className="topnav__sticky--item">
          <a href="https://wa.link/c4ydgr" target="_blank" rel="noreferrer">
            <img
              src={whatsapp}
              alt="קישור לוואטסאפ"
              className="topnav__sticky--img"
            />
          </a>
        </div>
        <div className="topnav__sticky--item">
          <a
            href="https://www.facebook.com/BraunGuitarSchool"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={facebook}
              alt="קישור לפייסבוק"
              className="topnav__sticky--img"
            />
          </a>
        </div>
      </div>
      <div className="topnav">
        <div
          className="topnav__icon"
          onClick={() => setClicked((prev) => !prev)}
        >
          <img
            src={clicked ? menuClose : menu}
            alt="Menu"
            className="topnav__icon--img"
          />
        </div>
        <nav className={clicked ? "topnav__menu show" : "topnav__menu"}>
          <p
            onClick={() => handleNavClick(mainRef)}
            className="topnav__menu--item"
          >
            ראשי
          </p>
          <p
            onClick={() => handleNavClick(lessonsRef)}
            className="topnav__menu--item"
          >
            על השיעורים
          </p>
          <p
            onClick={() => handleNavClick(bioRef)}
            className="topnav__menu--item"
          >
            קצת עלי
          </p>
          <p
            onClick={() => handleNavClick(galleryRef)}
            className="topnav__menu--item"
          >
            תמונות
          </p>
          <p
            onClick={() => handleNavClick(videoRef)}
            className="topnav__menu--item"
          >
            וידאו
          </p>
          <p
            onClick={() => handleNavClick(contactRef)}
            className="topnav__menu--item"
          >
            יצירת קשר
          </p>
          <p
            onClick={() => handleNavClick(mapRef)}
            className="topnav__menu--item"
          >
            דרכי הגעה
          </p>
          <p className="topnav__menu--item">
            <a
              className="topnav__menu--item-link"
              href="https://www.facebook.com/BraunGuitarSchool"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={facebook}
                alt="לינק לפייסבוק"
                className="topnav__menu--item-social"
              />
            </a>
          </p>
          <p className="topnav__menu--item">
            <a
              className="topnav__menu--item-link"
              href="https://wa.link/c4ydgr"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={whatsapp}
                alt="לינק לוואטאפ"
                className="topnav__menu--item-social"
              />
            </a>
          </p>
        </nav>
        <div onClick={() => handleNavClick(mainRef)} className="topnav__logo">
          <img src={logo} alt="Logo" className="topnav__logo--img" />
        </div>
        <div
          onClick={() => setClicked(false)}
          className={clicked ? "overlay" : "overlay hide"}
        ></div>
      </div>
    </>
  );
}
