import family from "../assets/icons/family.png";
import bookshelf from "../assets/icons/bookshelf.png";
import social from "../assets/icons/social.png";
import location from "../assets/icons/location.png";

export default function Features({ lessonsRef, handleNavClick, contactRef }) {
  return (
    <>
      <div ref={lessonsRef} className="features">
        <h2 className="features__headline">על השיעורים</h2>
        <div className="features__content">
          <div className="features__item">
            <div className="features__item--icon">
              <img
                src={family}
                alt="משפחה"
                className="features__item--icon-img"
              />
            </div>
            <h3 className="features__item--headline">למי זה מתאים?</h3>
            <p className="features__item--text">
              לכולם! בין אם מעולם לא ניגנתם על כלי ובין אם אתם כבר מנגנים ורוצים
              להתמקצע. יש לי נסיון עשיר בעבודה עם ילדים בגילאי יסודי וגם עם
              בוגרים בכל הרמות.
            </p>
          </div>
          <div className="features__item">
            <div className="features__item--icon">
              <img
                src={bookshelf}
                alt="מדף ספרים"
                className="features__item--icon-img"
              />
            </div>
            <h3 className="features__item--headline">מה עושים בשיעור?</h3>
            <p className="features__item--text">
              תוכן השיעורים מותאם באופן שייחודי לטעמו המוזיקלי, שאיפותיו וצרכיו
              של כל תלמיד. קריאת תווים, תיאוריה מוזיקלית, טכניקות למידה, ליווי
              שירים, הפקת צליל, פרשנות וחשיבה אומנותית ועוד..
            </p>
          </div>
          <div className="features__item">
            <div className="features__item--icon">
              <img
                src={social}
                alt="מפגש חברתי"
                className="features__item--icon-img"
              />
            </div>
            <h3 className="features__item--headline">פעילויות נוספות?</h3>
            <p className="features__item--text">
              התלמידים משתתפים בשני קונצרטים בשנה. בנוסף, מתקיימים מפגשי
              כיתה\סדנת ביצוע, על בסיס קבוע. פעילויות אלה מטרתן להוות גורם
              מוטיבציה לתלמידים ללמוד ולהשתפר.
            </p>
          </div>
          <div className="features__item">
            <div className="features__item--icon">
              <img
                src={location}
                alt="סיכת מיקום על מפה"
                className="features__item--icon-img"
              />
            </div>
            <h3 className="features__item--headline">
              היכן מתקיימים השיעורים?
            </h3>
            <p className="features__item--text">
              השיעורים מתקיימים בסטודיו בביתי שבעיר רחובות, במיקום מרכזי ונגיש{" "}
              <span
                onClick={() => handleNavClick(contactRef)}
                className="features__item--text-maplink"
              >
                (דרכי הגעה)
              </span>
              . הורים שמגיעים עם ילדיהם יכולים לשבת אתנו בשיעור, או להמתין
              באיזור המתנה נוח.
            </p>
          </div>
        </div>
        <button
          onClick={() => handleNavClick(contactRef)}
          className="features__btn btn"
        >
          יצירת קשר
        </button>
      </div>
    </>
  );
}
