import btnLeft from "../assets/icons/icon-arrow-left.svg";
import btnRight from "../assets/icons/icon-arrow-right.svg";
import { useState } from "react";
import useMediaQuery from "./hooks/useMediaQuery";

export default function Video({ videoRef, clicked }) {
  const [currentSlideX, setCurrentSlideX] = useState(0);
  const isDesktop = useMediaQuery("(min-width: 620px)");
  const translate = {
    transform: `translateX(${currentSlideX}px)`,
  };

  function handleBtnRight() {
    setCurrentSlideX((prev) =>
      isDesktop
        ? prev <= -1168
          ? prev
          : prev - 584
        : prev <= -688
        ? prev
        : prev - 344
    );
  }

  function handleBtnLeft() {
    setCurrentSlideX((prev) =>
      isDesktop
        ? prev >= 1168
          ? prev
          : prev + 584
        : prev >= 688
        ? prev
        : prev + 344
    );
  }

  return (
    <>
      <h2 ref={videoRef} className="video__headline">
        וידאו
      </h2>
      <div
        className="video"
        style={clicked ? { zIndex: "-1" } : { zIndex: "0" }}
      >
        <ul className="video__track" style={translate}>
          <li className="video__track--slide">
            <iframe
              width={isDesktop ? "560" : "320"}
              height={isDesktop ? "320" : "180"}
              src="https://www.youtube-nocookie.com/embed/VEMfRCpGrIg"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </li>
          <li className="video__track--slide">
            <iframe
              width={isDesktop ? "560" : "320"}
              height={isDesktop ? "320" : "180"}
              src="https://www.youtube-nocookie.com/embed/C7MG2ThoLJg"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </li>
          <li className="video__track--slide">
            <iframe
              width={isDesktop ? "560" : "320"}
              height={isDesktop ? "320" : "180"}
              src="https://www.youtube-nocookie.com/embed/s8vOv522C3M"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </li>
          <li className="video__track--slide">
            <iframe
              width={isDesktop ? "560" : "320"}
              height={isDesktop ? "320" : "180"}
              src="https://www.youtube.com/embed/bpplkuMd5Tg?si=eBKsFFVV0NSNBaRZ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </li>
          <li className="video__track--slide">
            <iframe
              width={isDesktop ? "560" : "320"}
              height={isDesktop ? "320" : "180"}
              src="https://www.youtube.com/embed/mk5taZPyn1E"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </li>
        </ul>
      </div>
      <div className="video__btns">
        <div onClick={handleBtnRight} className="video__btns--btn btnRight">
          <img
            src={btnRight}
            alt="Arrow right"
            className="video__btns--btn-img"
          />
        </div>
        <div onClick={handleBtnLeft} className="video__btns--btn btnLeft">
          <img
            src={btnLeft}
            alt="Arrow left"
            className="video__btns--btn-img"
          />
        </div>
      </div>
    </>
  );
}
