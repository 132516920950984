export default function Attribution() {
  return (
    <>
      <div className="freepik">
        Icons made by
        <a
          href="https://www.flaticon.com/authors/freepik"
          title="Freepik"
          target="_blank"
          rel="noreferrer"
        >
          Freepik
        </a>
        from
        <a
          href="https://www.flaticon.com/"
          title="Flaticon"
          target="_blank"
          rel="noreferrer"
        >
          www.flaticon.com
        </a>
      </div>
    </>
  );
}
