import slide2 from "../assets/gallery/slide2.jpg";
import slide3 from "../assets/gallery/slide3.jpg";
import slide4 from "../assets/gallery/slide4.jpg";
import slide5 from "../assets/gallery/slide5.jpg";
import slide6 from "../assets/gallery/slide6.jpg";
import slide7 from "../assets/gallery/slide7.jpg";
import slide8 from "../assets/gallery/slide8.jpg";
import slide9 from "../assets/gallery/slide9.jpg";
import slide10 from "../assets/gallery/slide10.jpg";
import slide11 from "../assets/gallery/slide11.jpg";
import slide12 from "../assets/gallery/slide12.jpg";
import slide13 from "../assets/gallery/slide13.jpg";
import slide14 from "../assets/gallery/slide14.jpg";
import slide15 from "../assets/gallery/slide15.jpg";
import slide16 from "../assets/gallery/slide16.jpg";
import slide17 from "../assets/gallery/slide17.jpg";
import slide18 from "../assets/gallery/slide18.jpg";
import slide19 from "../assets/gallery/slide19.jpg";
import slide20 from "../assets/gallery/slide20.jpg";
import slide21 from "../assets/gallery/slide21.jpg";
import slide22 from "../assets/gallery/slide22.jpg";
import btnLeft from "../assets/icons/icon-arrow-left.svg";
import btnRight from "../assets/icons/icon-arrow-right.svg";
import { useState } from "react";
import useMediaQuery from "./hooks/useMediaQuery";
import { useEffect } from "react";

const slides = [
  {
    img: slide2,
    alt: "אסף בראון מנגן עם תלמיד בקונצרט גיטרה",
  },
  {
    img: slide3,
    alt: "תלמידת גיטרה בקונצרט",
  },
  {
    img: slide4,
    alt: "תלמיד גיטרה מנגן בקונצרט",
  },
  {
    img: slide5,
    alt: "תלמיד גיטרה מנגן בקונצרט",
  },
  {
    img: slide6,
    alt: "אסף בראון מנגן עם תלמיד בקונצרט גיטרה",
  },
  {
    img: slide7,
    alt: "אסף בראון מנגן עם תלמידה בקונצרט גיטרה",
  },
  {
    img: slide8,
    alt: "אסף בראון מנגן עם תלמידה בקונצרט גיטרה",
  },
  {
    img: slide9,
    alt: "אסף בראון מנגן עם תלמידים בקונצרט גיטרה",
  },
  {
    img: slide10,
    alt: "תלמיד גיטרה מנגן בקונצרט",
  },
  {
    img: slide12,
    alt: "תלמידת גיטרה בקונצרט",
  },
  {
    img: slide11,
    alt: "המורה אסף בראון ותלמידי גיטרה בקונצרט, תמונה קבוצתית",
  },
  {
    img: slide13,
    alt: "אסף בראון מנגן עם תלמידים בקונצרט גיטרה",
  },
  {
    img: slide14,
    alt: "תלמידות גיטרה מנגנות דואט בקונצרט",
  },
  {
    img: slide15,
    alt: "המורה אסף בראון ותלמידי גיטרה בקונצרט, תמונה קבוצתית",
  },
  {
    img: slide16,
    alt: "המורה אסף בראון ותלמידי גיטרה בקונצרט, תמונה קבוצתית",
  },
  {
    img: slide17,
    alt: "תלמיד גיטרה מנגן בקונצרט",
  },
  {
    img: slide18,
    alt: "אסף בראון מנגן עם תלמידים בקונצרט גיטרה",
  },
  {
    img: slide19,
    alt: "תלמידת גיטרה בקונצרט",
  },
  {
    img: slide20,
    alt: "תלמיד גיטרה מנגן בקונצרט",
  },
  {
    img: slide21,
    alt: "אסף בראון ותלמידת גיטרה בשיעור",
  },
  {
    img: slide22,
    alt: "אסף בראון ותלמיד גיטרה בשיעור",
  },
];

export default function Gallery({ galleryRef }) {
  const isDesktop = useMediaQuery("(min-width: 620px)");
  const [currentSlideX, setCurrentSlideX] = useState(isDesktop ? -5700 : -2850);

  useEffect(() => {
    setCurrentSlideX(isDesktop ? -5700 : -2850);
  }, [isDesktop]);

  const translate = {
    transform: `translateX(${currentSlideX}px)`,
  };

  function handleBtnRight() {
    setCurrentSlideX((prev) =>
      isDesktop
        ? prev <= -11400
          ? prev
          : prev - 570
        : prev <= -5700
        ? prev
        : prev - 285
    );
  }

  function handleBtnLeft() {
    setCurrentSlideX((prev) =>
      isDesktop
        ? prev >= 0
          ? prev
          : prev + 570
        : prev >= 0
        ? prev
        : prev + 285
    );
  }

  return (
    <>
      <h2 ref={galleryRef} className="gallery__headline">
        תמונות
      </h2>
      <div className="gallery">
        <ul className="gallery__track" style={translate}>
          {slides.map((slide, index) => {
            return (
              <li
                key={index}
                style={{
                  transform: `translateX(${
                    isDesktop ? 570 * index : 285 * index
                  }px)`,
                }}
                className="gallery__track--slide"
              >
                <img
                  src={slides[index].img}
                  alt={slides[index].alt}
                  className="gallery__track--slide-img"
                />
              </li>
            );
          })}
        </ul>
      </div>
      <div className="gallery__btns">
        <div onClick={handleBtnRight} className="gallery__btns--btn btnRight">
          <img
            src={btnRight}
            alt="Arrow right"
            className="gallery__btns--btn-img"
          />
        </div>
        <div onClick={handleBtnLeft} className="gallery__btns--btn btnLeft">
          <img
            src={btnLeft}
            alt="Arrow left"
            className="gallery__btns--btn-img"
          />
        </div>
      </div>
    </>
  );
}

// slide width desktop: 570px (540 + 30 gap)
// slide width mobile: 285px (270 + 15 gap)
