import avatar from "../assets/avatar.jpg";
import cv from "../assets/icons/cv.png";

export default function Bio({ bioRef }) {
  return (
    <>
      <h2 ref={bioRef} className="bio__headline">
        קצת עלי
      </h2>
      <div className="bio">
        <div className="bio__avatar">
          <img
            src={avatar}
            alt="אסף בראון עם גיטרה בחוץ"
            className="bio__avatar--img"
          />
        </div>
        <div className="bio__content">
          <div className="bio__content--icon">
            <img src={cv} alt="קורות חיים" className="bio__content--icon-img" />
          </div>
          <p className="bio__content--text">
            הי, אני אסף, מורה לגיטרה קלאסית בעיר רחובות. בעל תואר ראשון ושני
            בביצוע קלאסי מהאקדמיה למוזיקה בירושלים ואקדמית פרנץ ליסט בבודפשט,
            ותואר נוסף בחינוך מוזיקלי. בעל נסיון של 14 שנים בהוראת גיטרה באופן
            פרטני ובמוסדות שונים כגון קונסרבטוריונים, בתי ספר ועוד.
          </p>
        </div>
      </div>
    </>
  );
}
