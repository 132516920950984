import Topnav from "./components/Topnav";
import Header from "./components/Header";
import Features from "./components/Features";
import Gallery from "./components/Gallery";
import Video from "./components/Video";
import Bio from "./components/Bio";
import Form from "./components/Form";
import Map from "./components/Map";
import Footer from "./components/Footer";
import Attribution from "./components/Attribution";
import "./styles/App.css";
import { useState, useRef } from "react";
import ReactGA from "react-ga";
const TRACKING_ID = "G-MEW112QXDF"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const [clicked, setClicked] = useState(false);
  const mainRef = useRef(null);
  const lessonsRef = useRef(null);
  const bioRef = useRef(null);
  const galleryRef = useRef(null);
  const videoRef = useRef(null);
  const contactRef = useRef(null);
  const mapRef = useRef(null);
  function handleNavClick(elementRef) {
    window.scrollTo({
      top: elementRef.current.offsetTop - 85,
      behavior: "smooth",
    });
    setClicked(false);
  }

  return (
    <>
      <main className="container">
        <Topnav
          handleNavClick={handleNavClick}
          clicked={clicked}
          setClicked={setClicked}
          mainRef={mainRef}
          lessonsRef={lessonsRef}
          bioRef={bioRef}
          galleryRef={galleryRef}
          videoRef={videoRef}
          contactRef={contactRef}
          mapRef={mapRef}
        />
        <Header
          mainRef={mainRef}
          handleNavClick={handleNavClick}
          contactRef={contactRef}
        />
        <Features
          lessonsRef={lessonsRef}
          handleNavClick={handleNavClick}
          contactRef={contactRef}
          mapRef={mapRef}
        />
        <Bio bioRef={bioRef} />
        <Gallery galleryRef={galleryRef} />
        <Video videoRef={videoRef} clicked={clicked} />
        <Form contactRef={contactRef} />
        <Map mapRef={mapRef} />
        <Footer
          handleNavClick={handleNavClick}
          mainRef={mainRef}
          lessonsRef={lessonsRef}
          bioRef={bioRef}
          galleryRef={galleryRef}
          videoRef={videoRef}
          contactRef={contactRef}
          mapRef={mapRef}
        />
      </main>
      <Attribution />
    </>
  );
}

export default App;
