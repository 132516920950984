import hero3 from "../assets/hero3.jpg";

export default function Header({ mainRef, handleNavClick, contactRef }) {
  return (
    <>
      <div ref={mainRef} className="header">
        <div className="header__content">
          <h1 className="header__headline">אסף בראון - שיעורי גיטרה ברחובות</h1>
          <p className="header__text">
            לימודי נגינה בגיטרה ברחובות לכל הרמות והגילאים בכיף ובהנאה רבה!
          </p>
          <button
            onClick={() => handleNavClick(contactRef)}
            className="header__btn btn"
          >
            יצירת קשר
          </button>
        </div>
        <div className="header__hero">
          <img
            src={hero3}
            alt="מורה ותלמידה בשיעור גיטרה"
            className="header__hero--img"
          />
        </div>
      </div>
    </>
  );
}
