export default function Form({ contactRef }) {
  // function handleSubmitClick(e) {
  //   e.preventDefault();
  // }
  return (
    <>
      <h2 ref={contactRef} className="form__headline">
        יצירת קשר
      </h2>
      <div className="form">
        <p className="form__text">
          לפרטים נוספים ותיאום מפגש ראשוני השאירו פרטים ואחזור אליכם בהקדם.
          לחילופין תוכלו לפנות אלי ישירות{" "}
          <a
            className="form__text--link"
            href="https://wa.link/c4ydgr"
            target="_blank"
            rel="noreferrer"
          >
            בוואטסאפ
          </a>
        </p>
        <form name="contact" method="post" className="form__form">
          <input type="hidden" name="form-name" value="contact" />
          <label htmlFor="nameInput">שם מלא:</label>
          <input
            id="nameInput"
            type="text"
            name="name"
            className="form__form--name"
            placeholder="שם מלא"
          />
          <label htmlFor="phoneInput">מספר טלפון:</label>
          <input
            id="phoneInput"
            type="text"
            name="phone"
            placeholder="מספר טלפון"
            className="form__form--phone"
          />
          <button type="submit" className="form__form--btn btn">
            שליחה
          </button>
        </form>
      </div>
    </>
  );
}
